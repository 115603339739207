<template>
  <app-modal
    id="why-trust-us-modal"
    class="why-trust-us-modal"
    title="Нам доверяют, почему?"
  >
    <template #modalContent>
      <div class="why-trust-us-modal">
        <img
          class="why-trust-us-modal__main-icon"
          src="@/assets/img/icons/calculations/why-trust-us-icon.svg"
          alt=""
        >

        <img
        class="why-trust-us-modal__main-icon--mobile"
          src="@/assets/img/icons/calculations/why-trust-us-mobile-icon.svg"
          alt=""
        >

        <div class="why-trust-us-modal__item">
          <div class="d-flex">
            <div class="item__left-icon">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M2.75 11.5C2.75 11.0858 3.08579 10.75 3.5 10.75H9C9.4142 10.75 9.75 11.0858 9.75 11.5C9.75 11.9142 9.4142 12.25 9 12.25H3.5C3.08579 12.25 2.75 11.9142 2.75 11.5Z" fill="#448AFF"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M4.75 2.5C4.75 2.08579 5.0858 1.75 5.5 1.75H9.25C10.3109 1.75 11.3283 2.17143 12.0785 2.92158C12.8286 3.67172 13.25 4.68914 13.25 5.75C13.25 6.81085 12.8286 7.8283 12.0785 8.57845C11.3283 9.32855 10.3109 9.75 9.25 9.75H6.25V13.5C6.25 13.9142 5.9142 14.25 5.5 14.25C5.0858 14.25 4.75 13.9142 4.75 13.5V9.75H3.5C3.08579 9.75 2.75 9.4142 2.75 9C2.75 8.5858 3.08579 8.25 3.5 8.25H4.75V2.5ZM6.25 8.25H9.25C9.91305 8.25 10.5489 7.9866 11.0177 7.51775C11.4866 7.04895 11.75 6.41305 11.75 5.75C11.75 5.08695 11.4866 4.45108 11.0177 3.98223C10.5489 3.51339 9.91305 3.25 9.25 3.25H6.25V8.25Z" fill="#448AFF"/>
              </svg>
            </div>

            <div class="d-flex flex-column">
              <div class="item__title">Цены как в страховой компании</div>

              <div class="item__subtitle">
                Предложение будет стоить одинаково на нашем сайте, в офисе, или на сайте страховой компании. У нас нет скрытых комиссий.
              </div>
            </div>

          </div>
        </div>

        <div class="why-trust-us-modal__item">
          <div class="d-flex">
            <div class="item__left-icon">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                <path d="M5.45652 7.16679C5.53652 7.17679 5.61319 7.18012 5.69319 7.18012C6.18652 7.18012 6.65985 7.01345 7.04652 6.70012C7.49652 6.33679 7.77985 5.82012 7.84319 5.24345C7.90652 4.66679 7.73985 4.10345 7.37652 3.65345C6.62652 2.72345 5.25985 2.57679 4.32985 3.32679C3.87985 3.69012 3.59652 4.20679 3.53319 4.78345C3.46985 5.36012 3.63652 5.92345 3.99985 6.37345C4.36652 6.82345 4.88319 7.10679 5.45652 7.16679ZM4.52985 4.89012C4.56319 4.58012 4.71652 4.30345 4.95652 4.10679C5.16652 3.93679 5.41985 3.85012 5.68652 3.85012C5.72985 3.85012 5.76985 3.85345 5.81319 3.85679C6.12319 3.89012 6.39985 4.04345 6.59652 4.28679C6.79319 4.53012 6.87985 4.83345 6.84652 5.14345C6.81319 5.45345 6.65985 5.73012 6.41985 5.92679C6.17985 6.12345 5.87319 6.21012 5.56319 6.17679C5.25319 6.14345 4.97652 5.99012 4.77985 5.74679C4.58652 5.50345 4.49652 5.20012 4.52985 4.89012Z" fill="#448AFF"/>
                <path d="M12.1866 9.62611C11.4366 8.69611 10.0699 8.54944 9.13991 9.29944C8.68991 9.66278 8.40658 10.1794 8.34325 10.7561C8.27991 11.3328 8.44658 11.8961 8.80991 12.3461C9.17325 12.7961 9.68991 13.0794 10.2666 13.1428C10.3466 13.1528 10.4232 13.1561 10.5032 13.1561C10.9966 13.1561 11.4699 12.9894 11.8566 12.6761C12.7866 11.9228 12.9366 10.5561 12.1866 9.62611ZM11.2299 11.8928C10.9866 12.0894 10.6832 12.1761 10.3732 12.1428C10.0632 12.1094 9.78658 11.9561 9.58991 11.7128C9.39325 11.4694 9.30658 11.1661 9.33991 10.8561C9.37325 10.5461 9.52658 10.2694 9.76658 10.0728C9.98325 9.89944 10.2399 9.81611 10.4966 9.81611C10.8366 9.81611 11.1766 9.96611 11.4066 10.2494C11.8099 10.7528 11.7299 11.4894 11.2299 11.8928Z" fill="#448AFF"/>
                <path d="M12.5334 4.21092C12.3601 3.99425 12.0468 3.96092 11.8301 4.13425L3.52342 10.8276C3.30675 11.0009 3.27342 11.3143 3.44675 11.5309C3.54675 11.6543 3.69009 11.7176 3.83675 11.7176C3.94675 11.7176 4.05675 11.6809 4.15009 11.6076L12.4568 4.91759C12.6734 4.74092 12.7068 4.42759 12.5334 4.21092Z" fill="#448AFF"/>
              </svg>
            </div>

            <div class="d-flex flex-column">
              <div class="item__title">Экономия до 30%</div>

              <div class="item__subtitle">
                Мы всегда следим за ценами и помогаем «поймать» самое выгодное предложение среди 20 + страховых.
              </div>
            </div>

          </div>
        </div>

        <div class="why-trust-us-modal__item">
          <div class="d-flex">
            <div class="item__left-icon">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                <g clip-path="url(#clip0_23901_153134)">
                  <path d="M13.9564 3.88725L10.2065 0.137344C10.119 0.0497812 9.9995 0 9.875 0H3.3125C2.53709 0 1.90625 0.630844 1.90625 1.40625V14.5938C1.90625 15.3692 2.53709 16 3.3125 16H12.6875C13.4629 16 14.0938 15.3692 14.0938 14.5938V4.21875C14.0938 4.09094 14.0401 3.97091 13.9564 3.88725ZM10.3438 1.60041L12.4933 3.75H10.8125C10.554 3.75 10.3438 3.53972 10.3438 3.28125V1.60041ZM12.6875 15.0625H3.3125C3.05403 15.0625 2.84375 14.8522 2.84375 14.5938V1.40625C2.84375 1.14778 3.05403 0.9375 3.3125 0.9375H9.40625V3.28125C9.40625 4.05666 10.0371 4.6875 10.8125 4.6875H13.1562V14.5938C13.1562 14.8522 12.946 15.0625 12.6875 15.0625Z" fill="#448AFF"/>
                  <path d="M10.8125 6.625H5.1875C4.92862 6.625 4.71875 6.83487 4.71875 7.09375C4.71875 7.35263 4.92862 7.5625 5.1875 7.5625H10.8125C11.0714 7.5625 11.2812 7.35263 11.2812 7.09375C11.2812 6.83487 11.0714 6.625 10.8125 6.625Z" fill="#448AFF"/>
                  <path d="M10.8125 8.5H5.1875C4.92862 8.5 4.71875 8.70987 4.71875 8.96875C4.71875 9.22763 4.92862 9.4375 5.1875 9.4375H10.8125C11.0714 9.4375 11.2812 9.22763 11.2812 8.96875C11.2812 8.70987 11.0714 8.5 10.8125 8.5Z" fill="#448AFF"/>
                  <path d="M10.8125 10.375H5.1875C4.92862 10.375 4.71875 10.5849 4.71875 10.8438C4.71875 11.1026 4.92862 11.3125 5.1875 11.3125H10.8125C11.0714 11.3125 11.2812 11.1026 11.2812 10.8438C11.2812 10.5849 11.0714 10.375 10.8125 10.375Z" fill="#448AFF"/>
                  <path d="M8.9375 12.25H5.1875C4.92862 12.25 4.71875 12.4599 4.71875 12.7188C4.71875 12.9776 4.92862 13.1875 5.1875 13.1875H8.9375C9.19637 13.1875 9.40625 12.9776 9.40625 12.7188C9.40625 12.4599 9.19637 12.25 8.9375 12.25Z" fill="#448AFF"/>
                </g>
                <defs>
                  <clipPath id="clip0_23901_153134">
                    <rect width="16" height="16" fill="white"/>
                  </clipPath>
                </defs>
              </svg>
            </div>

            <div class="d-flex flex-column">
              <div class="item__title">Подлинный полис ОСАГО</div>

              <div class="item__subtitle">
                Зарегистрируем ваш полис в базе страховщиков и ГИБДД. Полис примет любой сотрудник ГАИ.
              </div>
            </div>

          </div>
        </div>

        <div class="why-trust-us-modal__item mb-0">
          <div class="d-flex">
            <div class="item__left-icon">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
              <path d="M11 15.1673H5C4.17333 15.1673 3.5 14.494 3.5 13.6673V2.33398C3.5 1.50732 4.17333 0.833984 5 0.833984H11C11.8267 0.833984 12.5 1.50732 12.5 2.33398V13.6673C12.5 14.494 11.8267 15.1673 11 15.1673ZM5 1.83398C4.72667 1.83398 4.5 2.06065 4.5 2.33398V13.6673C4.5 13.9406 4.72667 14.1673 5 14.1673H11C11.2733 14.1673 11.5 13.9406 11.5 13.6673V2.33398C11.5 2.06065 11.2733 1.83398 11 1.83398H5Z" fill="#448AFF"/>
              <path d="M8.66683 3.5H7.3335C7.06016 3.5 6.8335 3.27333 6.8335 3C6.8335 2.72667 7.06016 2.5 7.3335 2.5H8.66683C8.94016 2.5 9.16683 2.72667 9.16683 3C9.16683 3.27333 8.94016 3.5 8.66683 3.5Z" fill="#448AFF"/>
            </svg>
            </div>

            <div class="d-flex flex-column">
              <div class="item__title">Удобное приложение</div>

              <div class="item__subtitle">
                Оформленный полис ОСАГО будет доступен в мобильном приложении даже без доступа к интернету.
              </div>
            </div>

          </div>
        </div>

        <div v-if="showButton" class="why-trust-us-modal__footer">
          <button
            class="btn btn-primary"
            @click="$hideModal('why-trust-us-modal')"
          >
            {{ confirmButtonLabel }}
          </button>
        </div>
      </div>

    </template>
  </app-modal>
</template>

<script>
export default {
  name: 'WhyTrustUsModal',
  props: {
    confirmButtonLabel: {
      type: String,
      default: 'К выбору предложения'
    },
    showButton: {
      type: Boolean,
      default: true,
    }
  }
}
</script>

<style lang="scss">
@import "@/assets/styles/_vars.scss";
@import "@/assets/styles/_mixins.scss";

.why-trust-us-modal {
  img {
    width: 100%;
    margin-bottom: 16px;
  }

  &__main-icon {
    display: none;

    @include view-lg {
      display: block;
    }

    &--mobile {
      display: block;

       @include view-lg {
        display: none;
      }
    }
  }


  &__footer {
    margin-top: 24px;

    button {
      display: inline-flex;
      padding: 14px 28px;
      justify-content: center;
      align-items: center;
      border-radius: 10px;
      gap: 10px;

      color: #FFF;
      font-size: 17px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      width: 100%;

      @include view-lg {
        width: fit-content;
      }
    }
  }

  &__item {
    margin-bottom: 12px;

    .item__left-icon {
      display: flex;
      width: 28px;
      height: 28px;
      padding: 6px;
      justify-content: center;
      align-items: center;
      border-radius: 4px;
      background: #F0F6FF;
      margin-right: 12px;
    }

    .item__title {
      color: $color-dark;
      font-size: 17px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      margin-bottom: 4px;
    }

    .item__subtitle {
      color: $color-gray;
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
    }
  }
}

#why-trust-us-modal {
  & > .popup-content {
    background: #fff !important;
  }
}
</style>
