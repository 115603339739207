import finesApi from '@rosfines/vue-common/common/api/finesApi';
import DeviceStorage from '@/util/helpers/device-storage';
import {
  VERSIONS_KBM_SPLIT_TEST,
  VERSIONS_ENTRY_PAGE_BTN_TEST,
  VERSIONS_PREMIUM_SUPPORT_TEST,
  VERSIONS_CALCULATIONS_TEST,
  VERSIONS_OFFERS_TEST,
} from '@/constants/abTests';

let resolveGBFeaturesLoad;
let GBFeaturesLoad;
function initGBFeaturesLoad () {
  GBFeaturesLoad = new Promise((resolve) => {
    resolveGBFeaturesLoad = resolve;
  });
}
initGBFeaturesLoad();

const GBFeatures = {
  namespaced: true,
  state() {
    return {
      featuresLoaded: false,
      featuresList: {},
    }
  },
  getters: {
    getByFeature: (state) => (feature) => {
      return state.featuresList[feature];
    },
    osagoWithTrailerEnabled(state, getters) {
      return !!getters["getByFeature"]("osago-with-trailer");
    },
    policyPromoEnabled(state, getters) {
      return !!getters["getByFeature"]("osago-referral-test");
    },
    insuranceAdditionalEnabled(state, getters) {
      return !!getters["getByFeature"]("insurance-additional-services");
    },
    fakedoorShareEnabled(state, getters) {
      return !!getters["getByFeature"]("fakedoor-share-osago-form-driver");
    },
    osagoIsTaxiEnabled(state, getters) {
      return !!getters["getByFeature"]("osago-is-taxi");
    },
    isVideoInstructionButtonEnabled (state, getters) {
      return !!getters["getByFeature"]("osago-video-instruction-button");
    },
    isOsagoSuccessPaymentBottomBannerEnabled(state, getters) {
      return !!getters["getByFeature"]("osago-success-payment-bottom-banner");
    },
    isLegalEntityEnabled(state, getters) {
      return !!getters["getByFeature"]("is-osago-legal-entity-enabled");
    },
    isEntryPageDisabledWithoutAdditionalInfo(state, getters) {
      return !!getters["getByFeature"]("osago-entry-page")?.isEntryPageDisabledWithoutAdditionalInfo;
    },
    isEntryPageDisabledWithAdditionalInfo(state, getters) {
      return !!getters["getByFeature"]("osago-entry-page")?.isEntryPageDisabledWithAdditionalInfo;
    },
    isOsagoEntryPageEnabled(state, getters) {
      return !!getters["getByFeature"]("osago-entry-page");
    },
    activeVersionIdKbmSplitTest(state, getters) {
      return getters["getByFeature"]('osago-kbm-split-test') ?? VERSIONS_KBM_SPLIT_TEST.OUTSIDE_THE_EXPERIMENT;
    },
    activeVersionEntryPageBtnName(state, getters) {
      return getters["getByFeature"]('osago-entry-page-btn-name') ?? VERSIONS_ENTRY_PAGE_BTN_TEST.OUTSIDE_THE_EXPERIMENT;
    },
    premiumSupportFeature(state, getters) {
      const featureValue = getters["getByFeature"]('osago-3733-premium-fakedoor-v3');
      return {
        isActive: VERSIONS_PREMIUM_SUPPORT_TEST.includes(featureValue),
        value: featureValue || null
      };
    },
    osagoHeaderWarning(state, getters) {
      return getters["getByFeature"]('osago-header-warning');
    },
    showSaucesPage(state, getters) {
      return getters["getByFeature"]('osago-3544-sauces');
    },
    activeVersionCalculationsTest(state, getters) {
      return getters["getByFeature"]('osago-calculations-version') ?? VERSIONS_CALCULATIONS_TEST.OUTSIDE_THE_EXPERIMENT;
    },
    activeVersionOffersTest(state, getters) {
      return getters["getByFeature"]('osago-offers-version') ?? VERSIONS_OFFERS_TEST.OUTSIDE_THE_EXPERIMENT;
    },
    ocrFakedoorCohort(state, getters) {
      return getters["getByFeature"]('ocr-fakedoor');
    },
    startScreenSingleInputCohort(state, getters) {
      return getters["getByFeature"]('start-screen-single-input');
    },
    isActiveFormExpiringPolicy(state, getters) {
      return !!getters["getByFeature"]('get-expiring-policy');
    }
  },
  mutations: {
    setFeaturesLoaded(state, value) {
      state.featuresLoaded = !!value;
    },
    setFeaturesList(state, list) {
      state.featuresList = list;
    },
  },
  actions: {
    async waitGBFeaturesLoad() {
      return GBFeaturesLoad;
    },
    async getFeatures({state, commit, dispatch}) {
      if (!state.featuresLoaded) {
        commit("setFeaturesLoaded", true);
        const savedList = DeviceStorage.GBFeatures;
        if (savedList.needToUpdate === false) {
          commit("setFeaturesList", savedList.features);
          resolveGBFeaturesLoad();
        } else {
          const response = await finesApi.get("/feature/config/config/osago");
          if (finesApi.isSuccess(response)) {
            commit("setFeaturesList", response.data.response.config.osago);
            DeviceStorage.GBFeatures = response.data.response.config.osago;
            resolveGBFeaturesLoad();
          } else {
            commit("setFeaturesLoaded", false);
            dispatch("getFeatures");
          }
        }
      }
    },
  },
}

export default GBFeatures;
