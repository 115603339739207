<template>
  <div
    v-if="!title && $router.hasBackParam() && !noEditTitle"
    class="page-header-wrapper"
    :class="wrapperClass"
  >
    <!-- редактирование данных -->
    <div
      class="page-header page-header_left page-title"
      :class="{
        'page-header_iso': hasAppHeader,
      }"
    >
      <div
        class="d-lg-none"
        @click="goBack()"
      >
        <img src="@/assets/img/controls/arrow-back.svg" alt="">
      </div>
      <h1 class="text-nowrap">
        Редактирование данных
      </h1>
      <div class="flex-grow-1"></div>
      <support-notifications
        :black="true"
        class="flex-grow-0"
      />
    </div>
  </div>
  <div
    v-else-if="title && hideVP"
    class="page-header-wrapper"
    :class="wrapperClass"
  >
    <!-- всякие белые шапки -->
    <div
      class="page-header page-title"
      :class="{
        'page-header_iso': hasAppHeader,
      }"
    >
      <div
        class="d-lg-none"
        @click="gotoPrevPage()"
      >
        <img src="@/assets/img/controls/arrow-back.svg" alt="">
      </div>
      <div class="text-nowrap flex-grow-1">
        <h1 class="text-nowrap flex-grow-1">
          {{ title }}
        </h1>
        <span v-if="subtitle" class="text-nowrap flex-grow-1">
          {{ subtitle }}
        </span>
      </div>
      <div class="flex-nowrap flex-grow-1 d-flex">
        <slot name="right" />
      </div>
      <div class="d-none d-lg-flex justify-content-right align-items-center">
        <slot name="right-lg" />
      </div>
      <support-notifications
        :black="true"
        class="flex-grow-0"
      />
    </div>
  </div>
  <div
    v-else-if="vehiclePlateNumberHTML"
    class="page-header-wrapper"
    :class="{
      'page-header-wrapper-no-title': !showTitle,
      wrapperClass: true,
    }"
  >
    <!-- синяя с выбором машины -->
    <div class="page-header car-selector">
      <div v-if="!withoutTitle" class="d-flex flex-grow-1 flex-column">
        <h1
          v-if="showTitle"
          class="d-none d-lg-block flex-grow-1"
        >
          {{ (title) ? title : "ОСАГО за 5 минут" }}
        </h1>
        <span class="d-none d-lg-block flex-grow-1">{{ subtitle }}</span>
      </div>
      <div
        v-if="isUserHasCars"
        class="vp-wrapper d-flex justify-content-center align-items-center"
      >
        <picture v-if="getActiveCarCategory === 'A'" class="car-ico">
          <source
            srcset="@/assets/img/landing2/moto-black.svg"
            media="(min-width: 992px)"
          >
          <img src="@/assets/img/landing2/moto-white.svg" alt="" />
        </picture>
        <picture v-else class="car-ico">
          <source
            srcset="@/assets/img/landing2/car-black.svg"
            media="(min-width: 992px)"
          >
          <img src="@/assets/img/landing2/car.svg" alt="" />
        </picture>

        <div
          v-if="vehiclePlateNumberHTML"
          class="landing__car__grz d-flex"
        >
          <div v-html="vehiclePlateNumberHTML" />
          <div class="region-code">
            {{ vehiclePlateRegion }}
          </div>
        </div>
        <div
          v-else
        >
          <a
            class="el_number el_number__button"
          >
            <span>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M18.7725 7.04723C19.0758 7.35052 19.0758 7.84046 18.7725 8.14375L17.3493 9.5669L14.433 6.65061L15.8562 5.22747C16.1595 4.92418 16.6494 4.92418 16.9527 5.22747L18.7725 7.04723ZM5 18.9998V16.0835L13.6011 7.48243L16.5174 10.3987L7.91628 18.9998H5Z" fill="#20273D"/>
              </svg>
            </span>
          </a>
          <div
            class="landing__car__grz d-flex mr-auto"
          >
            Без госномера
          </div>
        </div>

        <button-component
          type="button"
          class="btn btn_small btn-choose-car"
          @click="chooseAnotherCarHandler"
        >
          Другое авто
        </button-component>
      </div>

      <support-notifications/>
    </div>
  </div>
  <div
    v-else
    class="page-header-wrapper"
    :class="{
      'page-header-wrapper-no-title': !showTitle,
      wrapperClass: true,
    }"
  >
    <div class="page-header car-selector">
      <h1
        v-if="showTitle && !withoutTitle"
        class="d-none d-lg-block flex-grow-1"
      >
        {{ (title) ? title : "ОСАГО за 5 минут" }}
      </h1>
      <div
        v-if="isUserHasCars"
        class="vp-wrapper d-flex justify-content-center align-items-center"
      >
        <picture v-if="getActiveCarCategory === 'A'" class="car-ico">
          <source
            srcset="@/assets/img/landing2/moto-black.svg"
            media="(min-width: 992px)"
          >
          <img src="@/assets/img/landing2/moto-black.svg" alt="" />
        </picture>
        <picture v-else class="car-ico">
          <source
            srcset="@/assets/img/landing2/car-black.svg"
            media="(min-width: 992px)"
          >
          <img src="@/assets/img/landing2/car.svg" alt="" />
        </picture>

        <div
          v-if="vehiclePlateNumberHTML"
          class="landing__car__grz d-flex"
        >
          <div v-html="vehiclePlateNumberHTML" />
          <div class="region-code">
            {{ vehiclePlateRegion }}
          </div>
        </div>

        <div
          class="landing__car__grz d-flex mr-auto text-nowrap"
        >
          Без номера
        </div>
        <a
          class="el_number el_number__button"
          @click="onClickAddNumber(getActiveCarCategory === 'A' ? 'moto' : '')"
        >
          <span>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M18.7725 7.04723C19.0758 7.35052 19.0758 7.84046 18.7725 8.14375L17.3493 9.5669L14.433 6.65061L15.8562 5.22747C16.1595 4.92418 16.6494 4.92418 16.9527 5.22747L18.7725 7.04723ZM5 18.9998V16.0835L13.6011 7.48243L16.5174 10.3987L7.91628 18.9998H5Z" fill="#20273D"/>
            </svg>
          </span>
        </a>

        <button-component
          type="button"
          class="btn btn_small btn-choose-car"
          @click="chooseAnotherCarHandler"
        >
          Другое авто
        </button-component>
      </div>

      <support-notifications/>
    </div>
  </div>

  <warning-block
    v-if="osagoHeaderWarning"
    style="max-width: 960px; width: 100%; margin: 0 auto; margin-bottom: 20px"
    :title="osagoHeaderWarning?.title"
    :desc="osagoHeaderWarning?.description"
  />

  <add-plate-modal />
</template>

<script>
import urlHelper from "@/util/helpers/url.helper";
import {mapActions, mapGetters, mapState} from "vuex";
import ButtonComponent from "@/components/Button.vue";
import SupportNotifications from '@/components/support/SupportNotifications';
import { GET_PREV_PAGE } from '@/constants/flowMap';
import AddPlateModal from "@/components/modals/AddPlateModal.vue";
import WarningBlock from "@/components/blocks/misc/WarningBlock.vue";

let lastKnownScrollPosition = 0;
let ticking = false;
let headerCorrection = 0;
const saveHeaderPos = function () {
  window.$(".page-header-wrapper .page-header").data("y", window.scrollY - window.$(".page-header-wrapper .page-header").outerHeight());
}
const stickHeader = function () {
  if (window.scrollY > parseInt(window.$(".page-header-wrapper .page-header").data("y"), 10) && !window.$(".page-header-wrapper").hasClass("sticked")) {
    window.$(".page-header-wrapper .page-header").css({
      top: parseInt(window.$(".page-header-wrapper .page-header").data("y"), 10) + headerCorrection
    });
  } else {
    window.$(".page-header-wrapper").addClass("sticked");
    saveHeaderPos();
    window.$(".page-header-wrapper .page-header").css({
      top: window.scrollY
    });
  }
}
const onScroll = function () {
  const knownScrollPosition = window.scrollY;
  if (!ticking) {
    window.requestAnimationFrame(() => {
      if (lastKnownScrollPosition < knownScrollPosition) {
        saveHeaderPos();
        window.$(".page-header-wrapper").removeClass("sticked");
      } else if (lastKnownScrollPosition > knownScrollPosition) {
        if (knownScrollPosition > 30) {
          stickHeader();
          window.$(".page-header-wrapper").addClass("fixed");
        } else {
          window.$(".page-header-wrapper").removeClass("fixed").removeClass("sticked");
        }
      }
      ticking = false;
      lastKnownScrollPosition = knownScrollPosition;
    });
    ticking = true;
  }
}
export default {
  name: 'PageHeader',
  components: {
    WarningBlock,
    AddPlateModal,
    ButtonComponent,
    SupportNotifications,
  },
  props: {
    title: {
      type: String,
      required: false,
      default: ""
    },
    subtitle: {
      type: String,
      required: false,
      default: null,
    },
    showTitle: {
      type: Boolean,
      required: false,
      default: true,
    },
    hideVP: {
      type: Boolean,
      required: false,
      default: false
    },
    customVp: {
      type: String,
      required: false,
      default: ""
    },
    customBackEvent: {
      type: Boolean,
      required: false,
      default: false
    },
    wrapperClass: {
      type: String,
      required: false,
      default: '',
    },
    withoutTitle: {
      type: Boolean,
      default: false,
    },
    noEditTitle: {
      type: Boolean,
      default: false,
    }
  },
  emits: [
    "backBtnPressed",
  ],
  computed: {
    ...mapState({
      hasAppHeader: state => state.app.showAppHeader,
    }),
    ...mapGetters({
      getActiveCarVP: "form/getActiveCarVP",
      getActiveCarVin: "form/getActiveCarVin",
      getActiveCarId: "form/getActiveCarId",
      getActiveCarCategory: "form/getActiveCarCategory",
      getCarsList: "cars/getCarsList",
      osagoHeaderWarning: "GBFeatures/osagoHeaderWarning",
    }),
    vehiclePlateNumberHTML() {
      let vehiclePlate = this.customVp || this.getActiveCarVP;
      if (vehiclePlate){
        return vehiclePlate.split(/(\d+)/).filter(Boolean).slice(0, -1).map((val) => {
          if (isNaN(val)) {
            return '<span>' + val + '</span>'
          } else {
            return '<span style="font-size:17px">' + val + '</span>'
          }
        }).join('')
      } else if (this.getActiveCarVin) {
        return false;
      } else {
        return ''
      }
    },
    vehiclePlateRegion() {
      let vehiclePlate = this.customVp || this.getActiveCarVP;
      if (vehiclePlate){
        const found = vehiclePlate.match(/(\d+)$/);

        if (found) {
          return found.shift()
        }
      }

      return '';
    },
    isUserHasCars() {
      return this.getCarsList.length > 0;
    }
  },
  methods: {
    ...mapActions({
      addVehiclePlate: "cars/addVehiclePlate",
      setEditableCarId: "form/setEditableCarId",
      setEditableVehicleType: "form/setEditableVehicleType",
    }),
    async chooseAnotherCarHandler() {
      try {
        await this.$router.push({name: "chooseCar", query: urlHelper.getQueryParams()}).catch((e) => {console.error(e)});
      } catch(e) {
        console.error(e);
      }
    },
    goBack() {
      if (this.customBackEvent){
        this.$emit("backBtnPressed");
      } else {
        this.$router.go(-1);
      }
    },
    onClickAddNumber(vehicleType) {
      this.setEditableCarId(this.getActiveCarId);
      this.setEditableVehicleType(vehicleType);
      this.$showModal("modal-add-vehicle-plate");
    },
    async gotoPrevPage() {
      const name = GET_PREV_PAGE(this.$route.name);
      if (name) {
        await this.$router.push({
          name
        });
      }
    }
  },
  mounted() {
    document.addEventListener('scroll', onScroll, true);
    headerCorrection = this.hasAppHeader ? 56 : 0;
  },
  beforeUnmount() {
    document.removeEventListener('scroll', onScroll, false);
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/styles/_vars.scss";
@import "@/assets/styles/_mixins.scss";

.page-header-wrapper {
  height: 48px;
  margin-bottom: $header-margin;
  &.fixed {
    .page-header {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      z-index: 10;
      &_iso {
      }
      @include view-lg {
        position: static !important;
      }
    }
    &.sticked {
      .page-header {
        position: fixed;
        top: 0 !important;
        .has-app-header & {
          top: 56px !important;
        }
        @include view-lg {
          position: static !important;
        }
      }
    }
  }
  @include view-lg {
    height: auto;
    margin-bottom: 0;
  }
  &.page-header-wrapper-no-title {
    .page-header {
      @include view-lg {
        width: 388px;
        margin: auto;
      }
      .vp-wrapper {
        @include view-lg {
          flex-basis: 100%;
        }
      }
    }
  }

  h1 {
    font-weight: 600;
  }
}
.page-header {
  display: flex;
  column-gap: 12px;
  padding: 0 16px;
  justify-content: space-between;
  align-items: center;
  font-family: "SF Pro Display", sans-serif;
  &_iso {
    margin: 8px;
    border-radius: 16px;
    box-shadow: 5px 8px 30px 0 rgba(14, 46, 94, 0.20);
    width: calc(100% - 16px);
  }
  @include view-lg {
    margin: auto;
    max-width: 960px;
    padding-right: 0;
    padding-left: 0;
  }
  .narrow-page & {
    @include view-lg {
      max-width: 731px;
    }
  }
}

.page-title {
  padding-top: 15px;
  padding-bottom: 15px;
  box-shadow: 0 0 16px 2px rgba(32, 39, 61, 0.08);
  font-family: "SF Pro Display", sans-serif;
  background: $color-white;
  font-size: 17px;
  line-height: 22px;
  @include view-lg {
    padding-top: 41px;
    margin-bottom: 0;
    background: none;
    box-shadow: none;
  }
}
.car-selector {
  justify-content: center;
  padding-top: 12px;
  padding-bottom: 9px;
  border-bottom: 1px solid $color-main;
  background: $color-main;
  color: $color-white;
  font-size: 13px;
  @include view-lg {
    justify-content: space-between;
    border-bottom: none;
    background: none;
    color: $color-dark;
    margin-top: 7*$pixel-size;
    margin-bottom: 2*$pixel-size;
  }
  .btn_small {
    margin: -4px 0 -2px;
  }
}
.vp-wrapper {
  @include view-lg {
    width: auto;
    margin: 4px 0 0 0;
    padding: 16px 12px 14px;
    background: $color-white;
    border-radius: 16px;
    flex-basis: 1px;
  }
}
.arrow-left:after {
  margin: -6px 0 0 5px;
}
.landing__car__grz {
  margin: 0 12px 0 8px;
  @include view-lg {
    margin-right: 17px;
  }
}
.landing__car__grz {
  font-weight: 700;
}
.region-code {
  font-size: 12px;
  @include view-lg {
    margin-top: -4px;
    margin-left: 4px;
  }
}
.header-dark {
  background: $color-dark;
  &.car-selector {
    background: $color-dark;
    border-bottom-color: rgba(255, 255 ,255 , 0.1);
  }
}
.btn-choose-car {
  white-space: nowrap;
  background: $color-white;
  color: $color-dark;
  @include view-lg {
    background: #E9EBED;
    color: #20273D;
  }
}
h1 {
  display: flex;
}

.el_number {
  display: inline-block;
  padding: 5px 16px;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 600;
  height: 32px;
  text-transform: uppercase;
  background: $color-white;
  @include view-lg {
    background: $color-gray-white-100;
  }
  &_no-plate {
    font-size: 17px;
    padding: 5px 16px;
    text-transform: initial;
  }
  &__button {
    margin-right: 12px;
    margin-left: 8px;
    cursor: pointer;
    padding: 4px;
  }
}
</style>
